// src/utils/getIcon.js

import { ReactComponent as LaMayorLogoWhite } from "../icons/restaurants/la-mayor-logo-white.svg";
import { ReactComponent as LaMayorLogoBlack } from "../icons/restaurants/la-mayor-logo-black.svg";
import { ReactComponent as YupikLogoBlack } from "../icons/restaurants/yupick-logo-black.svg";
import { ReactComponent as YupikLogoWhite } from "../icons/restaurants/yupick-logo-white.svg";
import { ReactComponent as PregoneroLogoWhite } from "../icons/restaurants/logo-pregonero-white.svg";
import { ReactComponent as PregoneroLogoBlack } from "../icons/restaurants/logo-pregonero-black.svg";
import { ReactComponent as IconSugerencias } from "../icons/dishTypeIcons/icon-sugerencias.svg";
import { ReactComponent as IconTablas } from "../icons/dishTypeIcons/icon-tablas.svg";
import { ReactComponent as IconSandwiches } from "../icons/dishTypeIcons/icon-sandwiches.svg";
import { ReactComponent as IconEnsaladas } from "../icons/dishTypeIcons/icon-ensaladas.svg";
import { ReactComponent as IconBocadillos } from "../icons/dishTypeIcons/icon-bocadillos.svg";
import { ReactComponent as IconTostas } from "../icons/dishTypeIcons/icon-tostas.svg";
import { ReactComponent as IconHamburguer } from "../icons/dishTypeIcons/icon-hamburguer.svg";
import { ReactComponent as IconVeganas } from "../icons/dishTypeIcons/icon-veganas.svg";
import { ReactComponent as IconCremas } from "../icons/dishTypeIcons/icon-cremas.svg";
import { ReactComponent as IconWaffles } from "../icons/dishTypeIcons/icon-waffles.svg";
import { ReactComponent as IconJugos } from "../icons/dishTypeIcons/icon-jugos.svg";
import { ReactComponent as IconSmoothies } from "../icons/dishTypeIcons/icon-jugos1.svg";
import { ReactComponent as IconCaprichos } from "../icons/dishTypeIcons/icon-caprichos.svg";
import { ReactComponent as IconPostres } from "../icons/dishTypeIcons/icon-postres.svg";
import { ReactComponent as IconCarnes } from "../icons/dishTypeIcons/icon-carnes.svg";
import { ReactComponent as IconPescados } from "../icons/dishTypeIcons/icon-pescados.svg";
import { ReactComponent as IconMariscos } from "../icons/dishTypeIcons/icon-mariscos.svg";
import { ReactComponent as IconSopas } from "../icons/dishTypeIcons/icon-sopas.svg";

import altramucesIcon from "../icons/allergenIcons/altramuces.svg";
import apioIcon from "../icons/allergenIcons/apio.svg";
import cacahuetesIcon from "../icons/allergenIcons/cacahuetes.svg";
import cascaraIcon from "../icons/allergenIcons/cascara.svg";
import glutenIcon from "../icons/allergenIcons/gluten.svg";
import sesamoIcon from "../icons/allergenIcons/sesamo.svg";
import crustaceosIcon from "../icons/allergenIcons/crustaceos-mariscos.svg";
import moluscosIcon from "../icons/allergenIcons/moluscos-caracoles.svg";
import lacteosIcon from "../icons/allergenIcons/lacteos.svg";
import mostazaIcon from "../icons/allergenIcons/mostaza.svg";
import dioxidoAzufreIcon from "../icons/allergenIcons/dioxido-azufre.svg";
import sojaIcon from "../icons/allergenIcons/soja.svg";
import huevosIcon from "../icons/allergenIcons/huevos.svg";
import pescadoIcon from "../icons/allergenIcons/pescado.svg";

const iconMapping = {
  LaMayorLogoWhite: LaMayorLogoWhite,
  LaMayorLogoBlack: LaMayorLogoBlack,
  PregoneroLogoWhite: PregoneroLogoWhite,
  PregoneroLogoBlack: PregoneroLogoBlack,
  YupikLogoBlack: YupikLogoBlack,
  YupikLogoWhite: YupikLogoWhite,
  "icon-sugerencias": IconSugerencias,
  "icon-tablas": IconTablas,
  "icon-sandwiches": IconSandwiches,
  "icon-ensaladas": IconEnsaladas,
  "icon-bocadillos": IconBocadillos,
  "icon-tostas": IconTostas,
  "icon-hamburguer": IconHamburguer,
  "icon-veganas": IconVeganas,
  "icon-cremas": IconCremas,
  "icon-waffles": IconWaffles,
  "icon-jugos": IconJugos,
  "icon-smoothies": IconSmoothies,
  "icon-caprichos": IconCaprichos,
  "icon-postres": IconPostres,
  "icon-carnes": IconCarnes,
  "icon-pescados": IconPescados,
  "icon-mariscos": IconMariscos,
  "icon-sopas": IconSopas,

  altramucesIcon: altramucesIcon,
  apioIcon: apioIcon,
  cacahuetesIcon: cacahuetesIcon,
  cascaraIcon: cascaraIcon,
  glutenIcon: glutenIcon,
  sesamoIcon: sesamoIcon,
  crustaceosIcon: crustaceosIcon,
  moluscosIcon: moluscosIcon,
  lacteosIcon: lacteosIcon,
  mostazaIcon: mostazaIcon,
  dioxidoAzufreIcon: dioxidoAzufreIcon,
  sojaIcon: sojaIcon,
  huevosIcon: huevosIcon,
  pescadoIcon: pescadoIcon,
};

const getIcon = (iconName) => {
  return iconMapping[iconName] || null;
};

export default getIcon;
