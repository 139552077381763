import React from "react";
import "./WhatsAppButton.css";
import { ReactComponent as WhatsAppIcon } from "../icons/whatsapp.svg"; // Asegúrate de tener este icono en src/icons

const WhatsAppButton = ({ whatsappNumber }) => {
  const handleClick = () => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=Hola%20quiero%20hacer%20un%20pedido`,
      "_blank"
    );
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <WhatsAppIcon className="whatsapp-icon" />
    </button>
  );
};

export default WhatsAppButton;
